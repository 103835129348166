import React, { useState, useEffect, useMemo } from 'react';
import ReactPlayer from 'react-player/lazy';
import HeaderVideoLandscape from '../../video/header-video-landscape.mp4';
import HeaderVideoPortrait from '../../video/header-video-potrait.mp4';
import Clock from '../../img/clock.png';
import User from '../../img/user.png';
import Folder from '../../img/folder.png';

const Header = () => {
	const [winWidth, setWinWidth] = useState(0);
	useEffect(() => {
		setWinWidth(window.innerWidth);
	}, [winWidth, setWinWidth]);
	function winWidthHandler() {
		setWinWidth(window.innerWidth);
	}
	window.addEventListener('resize', winWidthHandler);

	const headerWideo = useMemo(() => {
		return winWidth > 900 ? (
			<ReactPlayer
				url={HeaderVideoLandscape}
				playing={true}
				loop={true}
				controls={false}
				width={'100%'}
				height={'auto'}
				muted={true}
			/>
		) : (
			<ReactPlayer
				url={HeaderVideoPortrait}
				playing={true}
				loop={true}
				controls={false}
				width={'100%'}
				height={'auto'}
				muted={true}
			/>
		);
	}, [winWidth]);
	return (
		<header className='header' id='top'>
			<div className='header-top'>
				{headerWideo}
				<div className='header-text'>
					<p className='big-text'></p>
					<h1>KREIRAJ SVOJU USPJEŠNU BUDUĆNOST</h1>
					<p>
						Budi u toku sa prilikama koje nudimo i iskoristi šansu da
						unaprijediš svoj život.
					</p>
				</div>
			</div>

			<div className='header-bottom'>
				<div className='container'>
					<div className='header-item'>
						<h2>
							<img src={Clock} alt='Clock icon' />
							<span>Ja biram kad i koliko radim.</span>
						</h2>
						<p>Radeći ovaj posao mogu kreirati radni dan po svojoj mjeri.</p>
					</div>
					<div className='header-item'>
						<h2>
							<img src={User} alt='User icon' />
							<span>Sam svoj šef.</span>
						</h2>
						<p>Posao bez pritiska i stresa, a odluke donosim samostalno.</p>
					</div>
					<div className='header-item'>
						<h2>
							<img src={Folder} alt='Folder icon' />
							<span>Zanimljiv posao i odlična zarada.</span>
						</h2>
						<p>Kombinacija koja ti obezbjeđuje ispunjen i uspješan život.</p>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
