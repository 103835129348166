import React, { useState, useMemo, useEffect } from 'react';
import Pagination from '../pagination/Pagination';
import axios from 'axios';
import VideoFancy from '../video-fancy/VideoFancy';
import PlayBtn from '../../img/play.png';

let PageSize = 3;
const YouTubeVideos = (props) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [data, setData] = useState(null);
	const currentTableData = useMemo(() => {
		if (data !== null && data.length > 3) {
			const firstPageIndex = (currentPage - 1) * PageSize;
			const lastPageIndex = firstPageIndex + PageSize;
			return data.slice(firstPageIndex, lastPageIndex);
		} else {
			return data;
		}
	}, [data, currentPage]);

	useEffect(() => {
		axios
			.get(
				'https://www.googleapis.com/youtube/v3/playlistItems?key=AIzaSyCEXXUtGd-OdPiGb-kx0G3hn56xEGoCmHM&playlistId=PLN8cOAP0DlnzQeiaYUSTkEFxTWHxDuPMM&part=snippet'
			)
			.then((res) => {
				setData(res.data.items);
			})
			.catch((err) => {
				if (err) {
					return;
				}
			});
	}, []);
	const videoData =
		currentTableData !== null && currentTableData.length > 0 ? (
			currentTableData.map((item, key) => {
				return (
					<li key={key}>
						<div className='poster'>
							<div className='play-btn'>
								<img src={PlayBtn} alt='Play button' />
							</div>
							<a
								data-fancybox
								href={`https://www.youtube.com/watch?v=${item.snippet.resourceId.videoId}`}
							>
								<img
									className='inline'
									width='500'
									alt={item.snippet.title}
									src={item.snippet.thumbnails.high.url}
								/>
							</a>
						</div>
						<div className='yt-text-content'>
							<h2>{item.snippet.title}</h2>
							<span>{item.snippet.publishedAt.substr(0, 10)}</span>
							<p>{item.snippet.description}</p>
						</div>
					</li>
				);
			})
		) : (
			<li
				style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}
			>
				<strong style={{ textAlign: 'center' }}>
					"Uskoro videa sa dosadašnjih druženja"
				</strong>
			</li>
		);
	return (
		<div className='youtube-videos' id='info-sastanak'>
			<div className='container'>
				<div className='title'>
					<h2>INFO VEČE</h2>
					<p>
						A kako nam je bilo na prethodnim druženjima, možeš pogledati na
						videima sa dosadašnjih Info večeri.
					</p>
				</div>

				<ul className='cards'>
					<VideoFancy>{videoData}</VideoFancy>
				</ul>

				<Pagination
					className='pagination-bar'
					currentPage={currentPage}
					totalCount={data !== null ? data.length : 0}
					pageSize={PageSize}
					onPageChange={(page) => setCurrentPage(page)}
				/>
				<div className='bottom'>
					<p>
						Ukoliko želiš da otkriješ mogućnosti i prilike koje smo za tebe
						pripremili, prijavi se za Info veče i započnimo tvoju uspješnu
						priču.
					</p>
					<button onClick={props.onclick}>PRIJAVI SE</button>
				</div>
			</div>
		</div>
	);
};

export default YouTubeVideos;
