import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/navbar/NavBar';
import Home from './pages/Home';
function App() {
	return (
		<BrowserRouter>
			<div className='App'>
				<NavBar />
				<Routes>
					<Route path='/' element={<Home />} />
				</Routes>
			</div>
		</BrowserRouter>
	);
}

export default App;
