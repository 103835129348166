import React from 'react';
import { MdOutlineClose } from 'react-icons/md';

const InfoPopup = (props) => {
	return (
		<div className={`info-popup${props.isactive ? ' active' : ''}`}>
			<button className='close' onClick={props.onclick}>
				<MdOutlineClose />
			</button>
			<div className='card'>
				<h2>INFO VEČE</h2>
				{props.data !== '' ? (
					<div className='info'>
						<div dangerouslySetInnerHTML={{ __html: props.data }} />
						<p>Prijavi se i započnimo tvoju uspješnu priču.</p>
					</div>
				) : (
					<div className='info'>
						<p>Trenutno nema zakazano Info veče. </p>
						<p>
							Ostavi svoj e-mail i poslaćemo ti obavijest o prvom sljedećem
							terminu, prijavi se I pridruži nam se.
						</p>
					</div>
				)}
				<div className='form'>
					<form onSubmit={props.onsubmit}>
						<div className='form-group'>
							<input
								type='email'
								name='email'
								value={props.value}
								placeholder='E-mail'
								onChange={props.onchange}
							/>
							<p className={`${props.success ? 'success' : 'error'}`}>
								{props.message}
							</p>
						</div>
						<div className='btn-group'>
							<button type='submit'>PRIJAVI SE</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default InfoPopup;
