import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import Logo from '../../img/saleslogics-logo.png';
import { FaViber } from 'react-icons/fa';

const NavBar = () => {
	const [active, setActive] = useState(false);

	window.addEventListener('scroll', () => {
		const winScroll = window.pageYOffset;
		if (winScroll > 10) {
			document.querySelector('body').style.paddingTop = 70 + 'px';
			document.querySelector('.navbar').style.height = 70 + 'px';
			if (window.innerWidth <= 900) {
				document.querySelector('.logo a').style.height = 40 + 'px';
			} else {
				document.querySelector('.logo a').style.height = 60 + 'px';
			}
			document.querySelector('.menu').style.top = 70 + 'px';
		} else {
			document.querySelector('body').style.paddingTop = 97 + 'px';
			document.querySelector('.navbar').style.height = 97 + 'px';
			if (window.innerWidth <= 900) {
				document.querySelector('.logo a').style.height = 60 + 'px';
			} else {
				document.querySelector('.logo a').style.height = 70 + 'px';
			}
			document.querySelector('.menu').style.top = 117 + 'px';
		}
	});
	function activeHandler() {
		if (window.innerWidth <= 900) {
			setActive(!active);
		} else {
			return;
		}
	}
	return (
		<nav className='navbar'>
			<div className='nav-container'>
				<div className='logo'>
					<HashLink smooth to='/#top'>
						<img src={Logo} alt='Logo' />
					</HashLink>
				</div>
				<div className='mob-btn'>
					<button
						type='button'
						aria-label='Mobile button'
						className={`${active ? ' active' : ''}`}
						onClick={activeHandler}
					>
						<span></span>
						<span></span>
						<span></span>
					</button>
				</div>
				<ul className={`menu${active ? ' active' : ''}`}>
					<li>
						<HashLink smooth to='/#o-programu' onClick={activeHandler}>
							O programu
						</HashLink>
					</li>
					<li>
						<HashLink smooth to='/#iskustva' onClick={activeHandler}>
							Iskustva
						</HashLink>
					</li>
					<li>
						<HashLink smooth to='/#info-sastanak' onClick={activeHandler}>
							Info razgovor
						</HashLink>
					</li>
				</ul>
				<div className='viber'>
					<a href='viber://chat/?number=%2B38766922158' onClick={activeHandler}>
						<FaViber />
						<span>+387 66 922 158</span>
					</a>
				</div>
			</div>
		</nav>
	);
};

export default NavBar;
