import React, { useEffect } from 'react';
import { Fancybox as NativeFancybox } from '@fancyapps/ui/dist/fancybox.esm.js';
import '@fancyapps/ui/dist/fancybox.css';

const VideoFancy = (props) => {
	const delegate = props.delegate || '[data-fancybox]';

	useEffect(() => {
		const opts = props.options || {};

		NativeFancybox.bind(delegate, opts);

		return () => {
			NativeFancybox.destroy();
		};
	}, [delegate, props.options]);

	return <>{props.children}</>;
};

export default VideoFancy;
