import React, { useState } from 'react';
import axios from 'axios';
import { FaRegEnvelope } from 'react-icons/fa';
import { FiPhoneCall } from 'react-icons/fi';
import { FiMapPin } from 'react-icons/fi';

const Contact = () => {
	const [state, setState] = useState({
		fullName: '',
		email: '',
		msg: '',
		succMsg: '',
		success: false,
	});
	function changeHandler(ev) {
		const { name, value } = ev.target;
		setState({
			...state,
			[name]: value,
		});
	}
	function submitHandler(ev) {
		ev.preventDefault();
		const formdata = new FormData();
		formdata.append('name', state.fullName);
		formdata.append('email', state.email);
		formdata.append('msg', state.msg);
		axios
			.post('https://saleslogics.at/forme/kontakt.php', formdata, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => {
				setState({
					fullName: '',
					email: '',
					msg: '',
					succMsg: 'Poruka je uspješno poslana. Hvala!',
					success: true,
				});
				setTimeout(() => {
					setState({
						fullName: '',
						email: '',
						msg: '',
						succMsg: '',
						success: false,
					});
				}, 2000);
			})
			.catch((err) => {
				setState({
					fullName: '',
					email: '',
					msg: '',
					succMsg: 'Nešto nije u redu. Molimo pokušajte ponovo.',
					success: false,
				});
				setTimeout(() => {
					setState({
						fullName: '',
						email: '',
						msg: '',
						succMsg: '',
						success: false,
					});
				}, 2000);
			});
	}
	return (
		<div className='contact'>
			<div className='container'>
				<div className='contact-text'>
					<h2>Imaš pitanja?</h2>
					<p>
						Popuni kratku kontakt formu, slobodno postavi pitanje, a mi ćemo ti
						rado odgovoriti i dati potrebne informacije o svemu šta te zanima.
					</p>
					<ul>
						<li>
							<FaRegEnvelope />{' '}
							<a href='mailto: posao@saleslogics.at'>posao@saleslogics.at</a>
						</li>
						<li>
							<FiPhoneCall /> <a href='tel: +38766922158'>+387 66 922 158</a>
						</li>
						<li>
							<FiMapPin /> 78000 Banja Luka
						</li>
					</ul>
				</div>
				<div className='contact-form'>
					<form onSubmit={submitHandler}>
						<div className='form-group'>
							<label htmlFor='name'>Ime i prezime</label>
							<input
								type='text'
								name='fullName'
								id='name'
								value={state.fullName}
								placeholder='Ime i prezime'
								onChange={changeHandler}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='email'>Mail adresa</label>
							<input
								type='email'
								name='email'
								id='email'
								value={state.email}
								placeholder='example@mail.com'
								onChange={changeHandler}
							/>
						</div>
						<div className='form-group'>
							<label htmlFor='msg'>Tvoja poruka</label>
							<textarea
								name='msg'
								id='msg'
								value={state.msg}
								placeholder='Tekst poruke...'
								onChange={changeHandler}
							></textarea>
						</div>
						<p className={`${state.success ? 'success' : 'error'}`}>
							{state.succMsg}
						</p>
						<div className='btn-group'>
							<button type='submit'>POŠALJI</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Contact;
