import React from 'react';
import IgorPict from '../../img/igor.png';
import MarkoPict from '../../img/marko.png';
import Jelena from '../../img/jelena.png';
import MarkoVideo from '../../video/marko.mp4';
import IgorVideo from '../../video/igor.mp4';
import VideoFancy from '../video-fancy/VideoFancy';
import PlayBtn from '../../img/play.png';
import JelenaVideo from '../../video/jelena.mp4';

const Testemonials = () => {
	return (
		<div className='testemonials' id='iskustva'>
			<div className='container'>
				<div className='testemonials-top'>
					<h2>NAŠE USPJEŠNE PRIČE</h2>
					<div className='media-content'>
						<div className='play-btn'>
							<img src={PlayBtn} alt='Play button' />
						</div>
						<VideoFancy>
							<a data-fancybox href={JelenaVideo}>
								<img className='inline' alt='Jelena' src={Jelena} />
							</a>
						</VideoFancy>
					</div>
					<h4>Stephi i Philip</h4>
				</div>
				<div className='testemonials-bottom'>
					<div className='testemonial-item'>
						<div className='text-content'>
							<p>
								“ Otkada sam otkrio ovu divnu mogućnost da sarađujem sa ovom
								kompanijom, meni su se mnoga vrata u životu otvorila. Uvek sam
								težio tome da ostvarim finansijsku slobodu, a na današnji dan
								mogu i da živim od toga. Zato toplo preporučujem svima da sebi
								daju šansu i isprobaju nešto novo. “
							</p>
							<h4>Marko, 29 godina, Švajcarska</h4>
						</div>
						<div className='media-content'>
							<div className='play-btn'>
								<img src={PlayBtn} alt='Play button' />
							</div>
							<VideoFancy>
								<a data-fancybox href={MarkoVideo}>
									<img
										className='inline'
										width='500'
										alt='Marko'
										src={MarkoPict}
									/>
								</a>
							</VideoFancy>
						</div>
					</div>
					<div className='testemonial-item'>
						<div className='text-content'>
							<p>
								“ U posljednje dvije godine, otkad sam upoznao ovaj koncept,
								vratio sam izgubljenu energiju i napustio svoj primarni biznis.
								Sada se bavim stvarima koje mi čine zadovoljstvo i što se tiče
								primanja, a i benefita i priznanja. Svima mogu preporučiti da
								iskoriste ovu šansu, jer je svakako to najbolja finansijska
								odluka koju sam donio u svom životu. “
							</p>
							<h4>Igor, 40 godina, otac dvoje djece</h4>
						</div>
						<div className='media-content'>
							<div className='play-btn'>
								<img src={PlayBtn} alt='Play button' />
							</div>
							<VideoFancy>
								<a data-fancybox href={IgorVideo}>
									<img
										className='inline'
										width='500'
										alt='Marko'
										src={IgorPict}
									/>
								</a>
							</VideoFancy>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Testemonials;
