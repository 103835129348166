import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaRegEnvelope } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';
import { FaViber } from 'react-icons/fa';
import Logo from '../../img/saleslogics-logo.png';

const Footer = (props) => {
	return (
		<footer>
			<div className='container'>
				<div className='footer-top'>
					<ul>
						<li>
							<HashLink smooth to='/#o-programu'>
								O programu
							</HashLink>
						</li>
						<li>
							<HashLink smooth to='/#iskustva'>
								Iskustva
							</HashLink>
						</li>
						<li>
							<HashLink smooth to='/#info-sastanak'>
								INFO RAZGOVOR
							</HashLink>
						</li>
					</ul>
					<div className='logo'>
						<HashLink smooth to='/#top'>
							<img src={Logo} alt='Logo' />
						</HashLink>
					</div>
					<div className='btn-group'>
						<HashLink
							to='/#info-sastanak'
							scroll={(el) =>
								el.scrollIntoView({ behavior: 'smooth', block: 'end' })
							}
						>
							Prijavi se na info sastanak
						</HashLink>
					</div>
				</div>
				<div className='footer-bottom'>
					<div className='social'>
						<a
							href='https://instagram.com/pokreni.posao'
							target='_blank'
							rel='noreferrer'
						>
							<FaInstagram />
						</a>
						<a
							href='https://www.facebook.com/Kako-pokrenuti-svoj-posao-105803468686128/'
							target='_blank'
							rel='noreferrer'
						>
							<FaFacebookF />
						</a>
						<a href='mailto: posao@saleslogics.at'>
							<FaRegEnvelope />
						</a>
						<a
							href='https://wa.me/38766922158'
							target='_blank'
							rel='noreferrer'
						>
							<FaWhatsapp />
						</a>
						<a href='viber://chat/?number=%2B38766922158'>
							<FaViber />
						</a>
					</div>
					<div className='developed'>
						<p>
							<a href='https://itexpertstudio.com/'>
								© IT Expert 2022. All rights reserved.
							</a>
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
