import React, { useState, useEffect } from 'react';
import Contact from '../components/contact/Contact';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import InfoPopup from '../components/info-popup/InfoPopup';
import Testemonials from '../components/testemonials/Testemonials';
import TopLife from '../components/top-life/TopLife';
import TopSuccess from '../components/top-success/TopSuccess';
import YouTubeVideos from '../components/youtube-videos/YouTubeVideos';
import axios from 'axios';

const Home = () => {
	const [isActive, setIsActive] = useState(false);
	const [data, setData] = useState('');
	const [val, setVal] = useState('');
	const [message, setMessage] = useState('');
	const [success, setSuccess] = useState(false);
	useEffect(() => {
		axios
			.get('https://saleslogics.at/postovi/wp-json/wp/v2/posts/', {
				mode: 'cors',
				credentials: 'include',
			})
			.then((res) => {
				setData(res.data[0].content.rendered);
			})
			.catch((err) => {
				if (err) {
					return;
				}
			});
	});
	function submitHandler(ev) {
		ev.preventDefault();
		const formdata = new FormData();
		formdata.append('email', val);
		axios
			.post('https://saleslogics.at/forme/email.php', formdata, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => {
				setVal('');
				setMessage('Uspješno ste se prijavili na info veče. Hvala!');
				setSuccess(true);
				setTimeout(() => {
					setMessage('');
					setSuccess(false);
				}, 2000);
			})
			.catch((err) => {
				setVal('');
				setMessage('Nešto nije u redu. Molimo pokušajte ponovo.');
				setSuccess(false);
				setTimeout(() => {
					setMessage('');
				}, 2000);
			});
	}
	function closeBtnClickHandler() {
		setIsActive(!isActive);
	}
	function onChangeHandler(ev) {
		setVal(ev.target.value);
	}
	return (
		<div className='home'>
			<Header />
			<TopLife />
			<TopSuccess onclick={closeBtnClickHandler} />
			<Testemonials />
			<YouTubeVideos onclick={closeBtnClickHandler} />
			<Contact />
			<Footer onclick={closeBtnClickHandler} />
			<InfoPopup
				isactive={isActive}
				data={data}
				value={val}
				message={message}
				onsubmit={submitHandler}
				success={success}
				onclick={closeBtnClickHandler}
				onchange={onChangeHandler}
			/>
		</div>
	);
};

export default Home;
