import React, { useState } from 'react';
import Laptop from '../../img/laptop.png';
import MenWithDaughter from '../../img/men-with-daughter.png';
import axios from 'axios';
const TopLife = () => {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [success, setSuccess] = useState(false);
	function onChangeHandler(ev) {
		setEmail(ev.target.value);
	}
	function onSubmitHandler(ev) {
		ev.preventDefault();
		const formdata = new FormData();
		formdata.append('email', email);
		axios
			.post('https://saleslogics.at/forme/email.php', formdata, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => {
				setEmail('');
				setMessage('Uspješno ste se prijavili na info veče. Hvala!');
				setSuccess(true);
				setTimeout(() => {
					setMessage('');
					setSuccess(false);
				}, 2000);
			})
			.catch((err) => {
				setEmail('');
				setMessage('Nešto nije u redu. Molimo pokušajte ponovo.');
				setSuccess(false);
				setTimeout(() => {
					setMessage('');
				}, 2000);
			});
	}
	return (
		<div className='top-life' id='o-programu'>
			<div className='container'>
				<h2>
					ŽIVI SVOJ VRHUNSKI
					<br /> ŽIVOT{' '}
				</h2>
				<div className='content'>
					<div className='image-content'>
						<img src={Laptop} alt='Laptop' />
						<img src={MenWithDaughter} alt='Men with Daughter' />
					</div>
					<div className='text-content'>
						<p>
							Kako bi bilo kada bi mogao birati koliko ćeš da radiš, kojim
							danima, koliko sati i tako da uvijek imaš vremena za porodicu,
							prijatelje, hobije i odmor?
						</p>
						<p>
							Kada bi uz to još i odlično zarađivao i radio posao sa svrhom,
							dobio bi recept za srećan i uspješan život. Odlična zarada,
							putovanja i fleksibilno radno vrijeme su benefiti koje ostvaruješ
							kroz ovu priliku.
						</p>
						<blockquote>
							Budi sam svoj šef i obezbjedi uspješnu
							<br /> i lijepu budućnost!
						</blockquote>
						<p>Pridruži nam se na Info veče i napravi prvi korak ka uspjehu!</p>
						<form onSubmit={onSubmitHandler}>
							<input
								type='email'
								name='email'
								placeholder='E-mail'
								value={email}
								onChange={onChangeHandler}
							/>
							<button type='submit'>PRIJAVI SE</button>
						</form>
						<p className={`${success ? 'success' : 'error'}`}>{message}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TopLife;
