import React, { useState } from 'react';
import axios from 'axios';
import { FaRegCheckSquare } from 'react-icons/fa';
import { FaViber } from 'react-icons/fa';
import Girl from '../../img/girl.png';

const TopSuccess = (props) => {
	const [email, setEmail] = useState('');
	const [success, setSuccess] = useState(null);
	const [message, setMessage] = useState('');

	function valueHandler(ev) {
		setEmail(ev.target.value);
	}
	function onSubmitHandler(ev) {
		ev.preventDefault();
		const formdata = new FormData();
		formdata.append('email', email);
		axios
			.post('https://saleslogics.at/forme/email.php', formdata, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => {
				setEmail('');
				setMessage('Uspješno ste se prijavili na info veče. Hvala!');
				setSuccess(true);
				setTimeout(() => {
					setMessage('');
					setSuccess(false);
				}, 2000);
			})
			.catch((err) => {
				setEmail('');
				setMessage('Nešto nije u redu. Molimo pokušajte ponovo.');
				setSuccess(false);
				setTimeout(() => {
					setMessage('');
				}, 2000);
			});
	}
	return (
		<div className='top-success'>
			<div className='container'>
				<div className='text-content'>
					<h2>
						Snalaziš se u marketingu i prodaji? Želiš se oprobati u multi –
						level marketingu?
					</h2>
					<p>
						Njemačka kompanija otvara svoja vrata prvi put na našim prostorima.
						Budi tu od samog početka i iskoristi ovu neponovljivu priliku.
					</p>
					<ul>
						<li>
							<FaRegCheckSquare /> Njemačka kompanija
						</li>
						<li>
							<FaRegCheckSquare /> 29 godina uspješnog poslovanja
						</li>
						<li>
							<FaRegCheckSquare /> Provjeren koncept
						</li>
						<li>
							<FaRegCheckSquare /> Na hiljade zadovoljnih korisnika
						</li>
					</ul>
					<div className='info'>
						<p>
							<a href='viber://chat/?number=%2B38766922158'>
								<FaViber />
								<span>+387 66 922 158</span>
							</a>
						</p>
						<h3>SAZNAJ O ČEMU JE RIJEČ</h3>
						<form onSubmit={onSubmitHandler}>
							<div className='input-group'>
								<input
									type='email'
									name='email'
									placeholder='E-mail'
									value={email}
									onChange={valueHandler}
								/>
							</div>
							<div className='btn-group'>
								<button type='submit'>PRIJAVI SE</button>
							</div>
							<p className={`${success ? 'success' : 'error'}`}>{message}</p>
						</form>
					</div>
				</div>
				<div className='image-content'>
					<img src={Girl} alt='Girl' />
				</div>
			</div>
		</div>
	);
};

export default TopSuccess;
